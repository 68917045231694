import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="About VUE" defer={false} />
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-2xl xl:text-3xl font-bold leading-none">
 			What is VUE?
 		  </h1>
 		  <p className="text-lg lg:text-xl mt-6 font-light">At its core, the Visual Understanding Environment (VUE) is a concept and content mapping application, developed to support teaching, learning and research and for anyone who needs to organize, contextualize, and access digital information. Using a simple set of tools and a basic visual grammar consisting of nodes and links, faculty and students can map relationships between concepts, ideas and digital content.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">Concept mapping is not new to the educational field. In fact, the benefits of concept mapping as a learning tool have been documented by over 40 years of cognitive science research. VUE provides a concept mapping interface, which can be used as such, or as an interface to organize digital content in non-linear ways.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">Numerous tools currently exist for locating digital information, but few applications are available for making sense of the information available to us. As the availability of digital information continues to increase, VUE sets itself apart as a flexible tool to help faculty and students integrate, organize and contextualize electronic content in their work. Digital content can be accessed via the Web, or using the VUE’s “Resources” panel to tap into digital repositories, FTP servers and local file systems.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">Sharing and presenting information are important aspects of academic work. VUE’s pathways feature allows presenters to create annotated trails through their maps, which become expert guided walk-throughs of the information. The pathways feature also provides a “slide view” of the information on the map. The power of VUE’s slide mode is the ability for presenters to focus on content (slide view) while preserving the information’s context (map view), by way of a single toggle between the two views.</p>
	      <p className="text-lg lg:text-xl mt-6 font-light">VUE also provides supports for in-depth analysis of maps, with  the ability to merge maps and export connectivity matrices to import in statistical packages. VUE  also provide tools to apply semantic meaning to the maps, by way of ontologies and metadata schemas.</p>
          <p className="text-lg lg:text-xl mt-6 font-light">VUE can be used by anyone interested in visually structuring digital content, whether in support of teaching difficult to understand concepts or more generally, a tool for organizing personal digital resources.</p>          
 		</div>
    </div>
</section>
</Layout>
</>
)